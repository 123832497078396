

.formContainer {
    width: 100%;
}


.tableDisplay {
    display: table;
    padding: 5px;
    width: 80%;
}
.tableRowDisplay {
    display: table-row;
    padding: 5px;
    width: 80%;
}

.tableCellDisplay {
    display: table-cell;
    padding: 5px;
    width: 150px;
    border: #000000;
    margin: 5px;
}

.inPutBox {
  border: none;
  border: 2px solid black;
  width: 350px;
}

.rightFloatDiv {
    float: right;
}

.evalFormContainerDiv {
    /*width: 40%;
    height: 22%;
    margin: auto;
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    padding: 20px;*/

    width: 75%;
    height: 35%;
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    padding: 20px;

    margin-left: 5%;
    margin-right: 5%;
}


/*exam timer*/


.timer {
    /*  background-color: rgba(255, 255, 255, 0.1);*/
  background-color: green;
  display: inline-block;
  text-align: center;
  width: 30%;
}

.examPage  {
    background-color: #0B0B45;
}

/*
    stop watch
*/

stopwatch-body {
    background-color: #000;
}

.stopwatch-time {
    text-align: center;
    color: #fff;
    font-size: 80px;
}

.stopwatch-buttons {
    display: flex;
    justify-content: center;
}

.stopwatch-buttons button {
    margin: 20px;
    border: none;
    padding: 10px 30px;
    cursor: pointer;
    color: #fff;
}

.stopwatch-buttons button:first-child {
    background-color: green;
}

.stopwatch-buttons button:last-child {
    background-color: red;
}


.ddDiv {
  float: left;
  width: 100px;
  height: 35px;
  margin: 10px;
  padding: 10px;
  border: 1px solid black;
}