.stop-watch {
    height: 20vh;
    width: 23vw;
    background-color: #0d0c1b;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}


/* control buttons */

<pre>.Control-Buttons {
    margin: 3rem 0;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-grp {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.btn {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    width: 10vw;
    height: 5vh;
    border-radius: 14px;
    margin: 0px 6px;
    display: flex;
    border: 2px solid #e42a2a;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #f5f5f5;
}

.btn-one {
    background-color: #e42a2a;
}

/*
Timer
*/

.timer {
    margin: 3rem 0;
    width: 100%;
    display: flex;
    height: 12%;
    justify-content: center;
    align-items: center;
}

.digits {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 3rem;
    color: #f5f5f5;
}

.mili-sec {
    color: #e42a2a;
}