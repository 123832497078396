.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.nav-header {
 width: 100%;
/* max-width: 800px*/
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}









/* new */


* {
  box-sizing: border-box;
}

/* Add a gray background color with some padding */
body {
  font-family: Arial;
  padding: 20px;
  background: #f1f1f1;
}

/* Header/Blog Title */
.header {
  padding: 30px;
  font-size: 40px;
  text-align: center;
  background: white;
}

/* Create two unequal columns that floats next to each other */
/* Left column */
.leftcolumn {
  float: left;
  width: 90%;
}

/* Right column */
.rightcolumn {
  float: right;
  width: 10%;
  padding-left: 4px;
}

/* Fake image */
.fakeimg {
  background-color: #oE4732;
  width: 10%;
  padding: 4px;
}

.blogDivCenter {
    width:800px;
    margin:0 auto;
}


/* Add a card effect for articles */
.card {
   background-color: white;
   padding: 10px;
   margin-top: 10px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Footer */
.footer {

 /* padding: 20px;
  text-align: center;
  background: #ddd;
  margin-bottom: 0px;*/

  /*
     position: fixed;
     left: 0;
     bottom: 0;
     width: 100%;
     background-color:  #ddd;
     background: #ddd;
     color: white;
     text-align: center;
     */


        left: 0;
        bottom: 0;
        width: 100%;
        background-color: black;
        color: white;
        text-align: center;
        position: fixed;

        height: 100px;
        width: 100%;

}

/* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 800px) {
  .leftcolumn, .rightcolumn {
    width: 100%;
    padding: 0;
  }
}


/*
 Navbar drop down menu */

 .navbar a {
   float: left;
   font-size: 16px;
   color: white;
   text-align: center;
   text-decoration: none;
 }

 .dropdown {
   float: left;
   overflow: hidden;
 }

 .dropdown .dropbtn {
   font-size: 16px;
   border: none;
   outline: none;
   color: white;
   padding: 14px 16px;
   background-color: inherit;
   font-family: inherit;
   margin: 0;
 }

 .navbar a:hover, .dropdown:hover .dropbtn {
   background-color: red;
 }

 .dropdown-content {
   display: none;
   position: absolute;
   background-color: #f9f9f9;
   min-width: 160px;
   box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
   z-index: 1;
 }

 .dropdown-content a {
   float: none;
   color: black;
   padding: 12px 16px;
   text-decoration: none;
   display: block;
   text-align: left;
 }

 .dropdown-content a:hover {
   background-color: #ddd;
 }

 .dropdown:hover .dropdown-content {
   display: block;
 }



/**
 DropDown navbar menu
*/

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-caret-down:before {
  content: "\f0d7";
}

* {
  box-sizing: border-box;
}

/* Create three equal columns that floats next to each other */
.menuColumn {
  float: left;
  width: 50%;
  padding: 13px;

}

 /* make-all-li-same-width-as-the-widest */
.menuColumn li {
    width: 100%;

}

/* Clear floats after the columns */
.menuRow:after {

  content: "";
  display: table;
  clear: both;

  height: 150px; /* height of popup menu div */
}

.navbar {
    max-width: 100vw;
    overflow: hidden;
    background-color: #333;


  color: #E69D32;
  font-family: "Times New Roman", Times, serif;
  font-size: 5px;

}



.navbar a {

  font-size: 5px;
  color: white;
  text-align: left;
  /*
  padding: 14px 16px;
  float: left;
  */
  text-decoration: none;


}

.subnav {
  float: left;
  overflow: auto;
}

.subnav .subnavbtn {
  font-size: 5px;
  border: none;
  outline: none;
  color: white;
  padding: 5px 5px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.navbar a:hover, .subnav:hover .subnavbtn {
  background-color: red;
}

.subnav-content {
  display: none;
  position: absolute;
 /* left: 0;*/
  background-color: black;
  width: 2%;
  z-index: 1;
}

.subnav-content a {
  float: left;
  color: white;
  text-decoration: none;
}

.subnav-content a:hover {
  background-color: #eee;
  color: black;
}

.subnav:hover .subnav-content {
  display: block;
}
/**
 End DropDown navbar menu
*/


. test-menu {
background-color: #78BE21;

}

.search-container {
  padding: 6px 10px;
  width: 80%;
  font-size: 17px;
  border: none;
  cursor: pointer;
}


/* new for wizard final form */

.register-wrapper {
    margin: auto;
    padding: 1em;
    margin-bottom: 1em;
    border: 1px solid #eee;
    background-color: #fff;
    box-shadow: 0 0 .25em rgba(0, 0, 0, 0.25);
}

 .register-left-div {
      float: left;
      margin-right: 80px;
    }

.register-right-div {
  float: right;
  margin-left: 80px;
}

.register-text {
    height: 150px;
    width: 200px;
}

.register-title {
    font-weight: bold;
    color: #777;
    display: table;
    font-size: 30px;
    margin: auto;
}

      /**
      * No border for more modern browsers.
      */
      :root .fieldset-wrapper {
        border: 0;
      }


      .field-text label {
        display: block;
      }
      .field-text label,
      .field-text input,
      .field-text .form-help,
      .field-text textarea {
        width: 100%;
        max-width: 40em;
        height: 35px;

        -moz-box-sizing: border-box;
             box-sizing: border-box;
      }
      .field-text input,
      .field-text textarea {
        padding: .375em .3125em .3125em;
        border: 1px solid #ccc;
        border-radius: .25em;

           height: 35px;
           width: 130px;
      }
      .field-text :focus {
        outline: 0;
        box-shadow: 0 0 .5em rgba(93, 162, 248, 0.5);
        border-color: #5da2f8;
      }
      .field-text :invalid {
        border-color: #e72633;
        box-shadow: 0 0 .5em rgba(229, 43, 37, 0.5);
      }


      .form-help {
        display: block;
        /* hide the help by default,
        without hiding it from screen readers */
        position: absolute;
        overflow: hidden;
        width: 1px;
        height: 1px;
        clip: rect(0, 0, 0, 0);
      }

      input:focus + .form-help {
        padding: .5em;
        margin-top: .5em;
        border: 1px solid #e1de8a;
        border-radius: .25em;
        font-style: italic;
        color: #737373;
        background-color: #fff;
        position: static;
        width: auto;
        height: auto;
        crop: none;
      }




/*
# tabs ===============*/
.tab-list {  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.tab-list-item {
  display: inline;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

# ==== more styles

.paper {

    display: 'flex';
    flexDirection: 'column';
    alignItems: 'center';
  }

  .timeoutPaper {
    padding: "3em";
    display: "flex";
    alignItems: "center";
    borderRadius: 0;
  }
  .message {
    display: "inline-block";
    marginLeft: "1em";
  }
  .icon {
    display: "inline-block";
    fontSize: "5rem";
    color: red[700];
  }
 .placeholderText {
    color: grey[800];
  }
  .title {
    fontSize: "1.5rem";
    color: grey[800];
    marginTop: "2em";
  }

.selectedTableRow {
    background-color: #FFCF8B;
}

/* Create two equal columns that floats next to each other */
.columnLeft{
  float: left;
  width: 20%;
}

/* Clear floats after the columns */
.pageRow:after {
  content: "";
  display: table;
  clear: both;
}

.lessonButton {
    width: 100%;
    text-align: left;
}

.lessonTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.lessonTableTh {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: black;
  color: white;
}

/* End new for wizard final form */


/* new grid for layout */

/* table display */
.table-row-display {
    display: table;
    width: 95%;
    table-layout: fixed;
    border-spacing: 5px;
    margin: 0px;
}

.table-column-display {
    display: table-cell;
/*    width: 10%;
    max-width: 10%;
    background-color: black;*/
}

.footerBg {
  /*background-color: black;*/
  padding-top: 30%;
}




/* new =============================== */

/* Hide my submenus by default */
.app-menu ul {
  display: none;
  position: absolute;
  left: 0;
  top: 10%;
}

/* Position submenus relative to parent list item */
.app-menu li {
  position: relative;
}

/* Look, Ma! No onclick handler! */
.app-menu li:hover > ul {
  display: block;
}

/* Modern Browsers only */
.app-menu li:focus-within > ul {
  display: block;
}



/* DEMO STYLES */
ul {
  list-style: none;
  padding: 0;
}

.app-menu {
  display: flex;
  justify-content: center;
  margin: 2% auto;
  max-width: 600px;
  width: 80%;
}

.app-menu a {
  display: block;
  padding: .5em 0;
  white-space: nowrap;
   font-size: 12px;
   padding: 7px 8px;
}

.app-menu a:hover {
  text-decoration: none;
}

.app-menu a:focus {
  outline: 2px dotted currentColor;
  outline-offset: 3px;
}

.app-menu > li + li {
  margin-left: 2em;
}

