/* css Zen Garden submission 214 - 'Verde Moderna', by Dave Shea, http://www.mezzoblue.com/ */
/* css released under Creative Commons License - http://creativecommons.org/licenses/by-nc-sa/1.0/  */
/* All associated graphics copyright 2013, Dave Shea */


/* IMPORTANT */
/* This design is not a template. You may not reproduce it elsewhere without the
   designer's written permission. However, feel free to study the CSS and use
   techniques you learn from it elsewhere. */


/*
	Verde Moderna by Dave Shea

	10 Years. http://mezzoblue.com/archives/2013/05/07/10_years/


	We've come a long way. Here it is, the first of what will hopefully be
	many more modern designs in the CSS Zen Garden.

	With this design, I've attempted to hit as many of today's front end
	buzzwords as possible. It's a mobile-first, responsive layout that was
	designed in the browser from start to finish. CSS3 is used liberally
	with fallbacks where necessary.

	All photos are mine, and were taken in the Japanese Garden at the
	Huntington Botanical Garden in Southern California.


	Other production notes:

	* Vector assets are used where appropriate. The logo is an SVG file,
	  the icons are a custom web font built with icomoon.io

	* The column effect is a CSS gradient instead of a background image.
	  By forcing a sharp transition at 66%, we can simulate columns. The way
	  Chrome rounds gradient values causes the columns to jump unexpectedly when
      the window is resized, which means that using an equal padding or width
      value doesn't align the way you think it would. That forced a bit of
      extra contortion to get the header and body columns perfectly lined up.

	* Various sidebar H3s were hidden with display:none; This was a design
	  decision. Their inclusion seemed extraneous. Same goes for HTML/CSS
      download links at low screen sizes, presumably that screen is a phone
      and can't download the files anyway.

	* The sidebar positioning style is (necessarily) quite a mess on wider
      windows, especially the resource list. When flexbox is a bit closer to
      usable, maybe I'll take another crack at fixing that up.

	* A more logical place for the design list to appear in the mobile
	  version would have been up closer to the top, possibly disclosed with a
      menu toggle of some kind. Not something that's easily doable with CSS
      alone, if you don't want to rely on hovers.

	* The medium- and low-res versions don't differ that much; I may still push
	  the tablet layout a bit further, it doesn't quite feel right.

	* Parallax scrolling isn't doable without Javascript so you won't find it
	  in this design. I was able to use a bit of multiple background-position
	  trickery to create a slightly more interesting scrolling effect.

*/


@import url(http://fonts.googleapis.com/css?family=Julius+Sans+One|Libre+Baskerville:400,400italic);

/* base CSS */
* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

html, body {
  padding: 0;
  margin: 0;
}
body {
  color: #325050;
  background: #f3f2e8;
  font-family: 'Libre Baskerville', sans-serif;
  font-size: 70%;
}

a {
  color: #0d8ba1;
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  text-decoration: none;
}
  a:visited {
    color: #1a5952;
  }
  a:hover, a:focus {
    color: #0599c2;
    text-decoration: underline;
  }
abbr {
  border: none;
  text-transform: uppercase;
  font-size: 0.9em;
  letter-spacing: 0.01em;
}
header {
  height: 230px;
  padding: 10px 0 10px 0;


/*  background: #2d6360 50% 0 url(huntington.jpg) no-repeat;*/ /* old IE fallback */
/* background: #f3f2e8 50% 0 url() no-repeat;*/ /* old IE fallback */

 /* background-attachment: fixed, fixed, fixed, scroll;*/
 /*background-image: url(contours.png), url(noise.png), url(gridlines.png), url(huntington.jpg);
*/
  background-position: 0 0, 0 0, -5px -25px, 0 50%;
  background-repeat: repeat, repeat, repeat, no-repeat;
  background-size: auto, auto, auto, cover;
  text-align: center;
}


h1, h2 {
  padding: 0 10%;
  margin: 0;
  /*color: #fff; */
  font-weight: normal;
}
h1 {
  padding-top: 0;
  padding-bottom: 5px;
  border-bottom: none;
  font-family: 'Julius Sans One', sans-serif;
  font-size: 3.2em;
  text-transform: uppercase;
}
h1::before {
  display: inline-block;
  position: relative;
  top: 20px;
  content: "";
  width: 80px;
  height: 50px;
  margin: -20px 20px 0 0;
 /* background: url(enso.svg);*/
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.6;
}
h2 {
  display: block;
  padding-top: 5px;
  padding-bottom: 10px;
  border-top: none;
  /*color: rgba(255,255,255,0.6); */

  font-size: 1.6em;
  font-style: italic;
}
h2::before {
  content: " ";
  padding: 50px;
}
h3 {
  margin: 2em 0 0 0;
  color: #2e484c;
  font-family: 'Julius Sans One', sans-serif;
  font-size: 1.8em;
  font-weight: normal;
  text-transform: uppercase;
}
p {
  margin: 0.75em 0;
  line-height: 2;
}



.summary, .preamble {
  width: 80%;
  margin: 0 auto;
}
.supporting h3, .supporting p {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.summary {
  padding-top: 2em;
}


/* intro block links -- download HTML/CSS */
.intro a, .intro a:visited {
  position: relative;
  display: inline-block;
  padding: 1px 10px 1px 32px;
  margin: -1px -10px -1px -6px;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;

  color: #809b7e;
  font-family: 'Julius Sans One', sans-serif;
  text-transform: uppercase;
}
.intro a:hover, .intro a:focus {
  z-index: 2;
  /*color: #fff; */

  background: #3d8a9f;
  text-decoration: none;
}

.intro a::before {
  display: inline-block;
  position: absolute;
  top: 6px;
  left: 8px;
  padding: 2px;
  color: #a9b995;
  background: #d9e1cd;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -o-border-radius: 2px;

  content: "D";
  font-size: 16px;
  text-indent: 0;


  /* icomoon.io defaults */
  font-family: 'verdemoderna';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;

  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}
  .intro a:hover::before {
    /*color: #fff;*/
    background: #357d93;
  }


/* coloured cutoff */
div.participation, div.benefits {
  background-attachment: scroll, scroll, fixed;
  background-color: #f3f2e8;
 /* background-image: url(contours-opaque.png), url(gridlines-opaque.png), url(bamboo.png);*/
  background-repeat: repeat, repeat, no-repeat;
  background-size: auto, auto, cover;
}
/* extra padding at the cutoffs */
div.participation {
  padding: 3em 0;
  margin-top: 3em;
}
div.benefits {
  padding-bottom: 3em;
  margin-bottom: 3em;
}
div.participation h3, div.benefits h3 {
  margin-top: 0;
}
div.participation p, div.benefits p {
  margin-bottom: 0;
}


/* sidebar base styles */
.sidebar {
  background: #f3f2e8;

}
.sidebar h3 {
  display: none;
}
.sidebar a {
  color: #607476;
  text-decoration: none;
}
  .sidebar a:hover, .sidebar a:focus {
    color: #49968e;
  }
.sidebar ul {
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
}


/* shared sidebar styling */
.design-selection ul, .zen-resources ul {
  margin: 0;
}
.design-selection li, .zen-resources li {
  float: left;
  width: 50%;
  padding: 1.5em 10%;
  border-top: solid 1px #d9e3dc;
  color: #c0cac3;
  font-style: italic;
}
  .design-selection li:hover, .design-selection li:focus {
    background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0.5)), color-stop(100%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%); /* IE10+ */
    background: linear-gradient(to right,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 100%); /* W3C */
  }
.design-selection li:nth-child(2n+1), .zen-resources li:nth-child(2n+1) {
  clear: left;
}
.design-selection .design-name, .zen-resources a, li.viewall a {
  display: block;
  margin-bottom: 0.25em;
  font-family: 'Julius Sans One', sans-serif;
  font-size: 1.2em;
  font-style: normal;
  text-transform: uppercase;
}


/* select a design */
.design-selection h3 {
  display: none;
}
.design-selection .designer-name {
  color: #616857;
  font-size: 0.9em;
  font-style: normal;
}



/* archives navigation */
.design-archives {
  position: absolute;
  top: 160px;
  right: 0;
  width: 100%;
  height: 70px;
  background: rgba(255,255,255,0.2);
}
.design-archives h3 {
  display: none;
}
.design-archives ul {
  margin: 0;
}
.design-archives li {
  position: absolute;
  top: 0;
  padding: 0;
  margin: 0;
  border: none;
  list-style: none;
}
.design-archives li.viewall {
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 71px;
  line-height: 50px;
}
.previous, .next {
  left: 0;
  z-index: 3;
}
.next {
  left: auto;
  right: 0;
}
.design-archives a, .design-archives a:visited {
  color: rgba(255, 255, 255, 0.65);
}
.design-archives a:hover, .design-archives a:focus {
 /* color: #fff; */
}
.design-archives .previous a, .design-archives .next a {
  display: block;
  overflow: hidden;
  width: 70px;
  height: 70px;
  text-indent: 100%;
  text-decoration: none;
  white-space: nowrap;
}
.design-archives .viewall a {
  z-index: 2;
  width: 100%;
  min-height: 70px;
  padding: 12px 0 0 0;
  text-align: center;
}
.design-archives a::before {
  position: absolute;
  left: 0;
  width: 70px;
  height: 70px;
  text-align: center;
  text-indent: 0;
  font-size: 44px;

  /* icomoon.io defaults */
  font-family: 'verdemoderna';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 70px;
  -webkit-font-smoothing: antialiased;
}
.design-archives .viewall a:hover, .design-archives .viewall a:focus, .design-archives .viewall a:active,
.design-archives a:hover::before, .design-archives a:focus::before, .design-archives a:active::before {
  background-color: rgba(255,255,255,0.25);
  box-shadow: inset 0 0 10px rgba(255,255,255,0.1);
}
.design-archives .previous a::before {
  content: "<";
}
.design-archives .next a::before {
  content: ">";
}



/* resources */
.zen-resources {
  overflow: hidden;
  background-color: rgba(138,188,159,0.5);
 /* background-image: url(bamboo.png);*/
}
.zen-resources li {
  border-top: solid 1px #a0b9ad;
  font-style: normal;
}
.zen-resources li:last-child {
  float: none;
  width: auto;
  border-bottom: solid 1px #a0b9ad;
}


/* busting out the credits block */
.requirements p:nth-child(5) {
  padding-bottom: 2em;
}
.supporting .requirements {
  float: none;
  clear: both;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.supporting .requirements a {
  text-decoration: none;
}
  .supporting .requirements a:hover, .supporting .requirements a:focus {
    text-decoration: underline;
  }
.supporting .requirements h3 {
  padding-top: 0;
}
.requirements p:last-child {
  width: auto;
  padding: 1em 12%;
  margin: 0;
  /*color: rgba(255, 255, 255, 0.4); */

  background: #f3f2e8;

  background-attachment: fixed, fixed, fixed, scroll;
  /*background-image: url(contours.png), url(gridlines.png), url(noise.png), url(koi.jpg);*/
  background-position: 0 0, -5px -25px, 0 0, 0 50%;
  background-repeat: repeat, repeat, repeat, no-repeat;
  background-size: auto, auto, auto, cover;

  font-size: 2em;
  font-style: italic;
  text-align: center;
}
.requirements p:last-child a {
  /*color: rgba(255, 255, 255, 0.7); */
  font-style: normal;
}



/* main footer */
footer {
  clear: both;
  padding: 3em 10%;
  /*color: #fff;*/
  background: #f3f2e8;
  text-align: center;
}
footer a {
  display: inline-block;
  overflow: hidden;
  width: 40px;
  height: 40px;
  padding: 40px 0 0 0;
  margin: 0 0.5em;
  position: relative;
  color: rgba(255, 255, 255, 0.5);
  -webkit-transition: color 0.25s ease-out, box-shadow 0.25s ease-out;
  -moz-transition: color 0.25s ease-out, box-shadow 0.25s ease-out;
  transition: color 0.25s ease-out, box-shadow 0.25s ease-out;
}
footer a:hover, footer a:focus {
  color: rgba(255, 255, 255, 0.3);
  text-decoration: none;
}
footer a:visited {
  color: rgba(255, 255, 255, 0.2);
}
footer a::before {
  display: block;
  position: absolute;
  top: 3px;
  left: 0;
  overflow: visible;
  font-size: 36px;
  text-indent: 0;

  /* icomoon.io defaults */
  font-family: 'verdemoderna';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
  footer a.zen-validate-html::before {content: "";}
  footer a.zen-validate-html:hover::before, footer a.zen-validate-html:focus::before {content: "%";}
  footer a.zen-validate-css::before {content: "3";}
  footer a.zen-validate-css:hover::before, footer a.zen-validate-css:focus::before {content: "#";}
  footer a.zen-license::before {content: "c";}
  footer a.zen-license:hover::before, footer a.zen-license:focus::before {content: "C";}
  footer a.zen-accessibility::before {content: "a";}
  footer a.zen-accessibility:hover::before, footer a.zen-accessibility:focus::before {content: "A";}
  footer a.zen-github::before {content: "g";}
  footer a.zen-github:hover::before, footer a.zen-github:focus::before {content: "G";}



/* low-res CSS */
@media only screen and (max-width: 600px) {

  /* these download links make approximately zero sense
     on a phone without a user-accessible file system. */
  .summary p:last-child {
    display: none;
  }

  /* type adjustments */
  h1 {
    margin-top: 50px;
    font-size: 2.5em;
  }
  h1::before {
    width: 50px;
    height: 10px;
  }
  h2::before {
    padding: 5px;
  }

  .requirements p:last-child {
    padding: 1em 6%;
  }

}


@media only screen and (max-width: 500px) {

  header {
    height: 150px;
    padding-top: 0;
  }
  h1 {
    margin: 0;
  }
  h1::before {
    display: block;
    left: 50%;
    width: 50px;
    height: 20px;
    margin: 0 0 0 -25px;
    padding: 0 0 15px 0;
  }
  h2::before {
    padding: 0;
  }

  .design-archives {
    top: 180px;
  }
  .design-selection li, .zen-resources li {
    padding: 1em 5%;
  }

  footer a {
    margin: 0 0.5em;
  }

}

@media only screen and (max-width: 320px) {

    /* adjusting the site header type size */
    h1 {
        font-size: 2em;
    }
    h2 {
        font-size: 1.3em;
    }

    /* linearizing the design list on small screens */
    .design-selection li, .zen-resources li {
        float: none;
        width: 100%;
    }

    /* adjusting footer type, icons */
    .requirements p:last-child {
        font-size: 1.4em;
    }
    footer {
        padding: 2em 5%;
    }
    footer a {
        width: 35px;
        height: 35px;
        padding: 35px 0 0 0;
        margin: 0 0.5em;
    }
    footer a::before {
        font-size: 32px;
    }

}


/* mid-res CSS */
@media only screen and (max-width: 1132px) {
  header {
    border-top: solid 10px rgba(19, 67, 71, 0.9);
  }
  p, li {
    font-size: 1.2em;
  }
}

@media only screen and (min-width: 500px) and (max-width: 1150px) {

  .requirements p:last-child {
    padding-bottom: 2em;
    padding-top: 2em;
  }

  /* page footer */
  footer {
    padding: 3em 0;
  }
  footer a {
    width: 52px;
    height: 52px;
    padding: 52px 0 0 0;
    margin: 0 2em;
  }
  footer a::before {
    font-size: 48px;
  }
}


/* high-res CSS */
/* background gradient affects the background color of right side bar*/
@media only screen and (min-width: 1132px) {
  body {
    /*background: #ffffff;*/
    /*background: -webkit-gradient(linear, left top, right top, color-stop(0%,#ffffff), color-stop(86%,#ffffff), color-stop(86%,#e5ede8), color-stop(100%,#e5ede8));
    background: -webkit-linear-gradient(left,  #ffffff 0%,#ffffff 86%,#e5ede8 86%,#e5ede8 100%);
    background: -o-linear-gradient(left,  #ffffff 0%,#ffffff 86%,#e5ede8 86%,#e5ede8 100%);
    background: -ms-linear-gradient(left,  #ffffff 0%,#ffffff 86%,#e5ede8 86%,#e5ede8 100%);
    background: linear-gradient(to right,  #ffffff 0%,#ffffff 86%,#e5ede8 86%,#e5ede8 100%);*/
    font-size: 100%;
  }
  header {
    width: 100%;
    height: 310px;
    padding: 0 10%;
    /* border-top: solid 12px rgba(19, 67, 71, 0.8); */
    box-shadow: inset 0 0 100px rgba(255, 255, 255, 0.3);
    vertical-align: middle;
    text-align: left;
  }

  header::before, header::after {
    display: block;
    content: " ";
    position: absolute;
    z-index: 3;
    top: 9px;
    left: 0;
    width: 100%;
    height: 7px;
    background: rgba(0, 39, 43, 0.2);
  }
  header::after {
    top: 306px;
    height: 4px;
    background: rgba(0, 39, 43, 0.075);
  }
  h1 {
    display: inline-block;
    position: relative;
    margin: 15px 0 0 0;
    padding: 60px 0 5px 0;
  }
  h1::before {
    display: inline-block;
    position: relative;
    top: 65px;
    content: "";
    width: 125px;
    height: 125px;
    margin: -65px 40px 0 0;
   /* background: url(enso.svg);*/
    background-size: 100%;
    opacity: 0.6;

    -webkit-animation: koan 36000s infinite alternate;
    -moz-animation: koan 36000s infinite alternate;
    animation: koan 36000s infinite alternate;
  }
  h2 {
    display: block;
    margin: 0;
    padding: 5px 0 60px 70px;
  }
  p {
    font-size: 1em;
  }

  /* main layout blocks */
  nav, .supporting {
    display: inline;
  }
  .summary, .preamble, .supporting div, .requirements h3, .requirements p {
    float: left;
    clear: left;
    width: 66%;
    padding: 1em 5% 1em 10%;
  }
  .explanation h3, .explanation p,
  .participation h3, .participation p,
  .benefits h3, .benefits p {
    width: 100%;
  }
  .summary {
    padding-top: 3.25em;
  }
  .requirements h3, .requirements p {
    margin: 0;
    padding-bottom: 0;
  }


  /* coloured cutoff */
  div.participation, div.benefits {
    width: 100%;
    padding-left: 10%;
    padding-right: 38%;
    margin: 0;
  }
  /* extra padding at the cutoffs */
  div.participation, div.requirements {
    padding-top: 4em;
  }
  div.explanation, div.benefits {
    padding-bottom: 4em;
  }
  .requirements p:nth-child(5) {
    padding-bottom: 4em;
  }


  .intro h3, .supporting h3 {
    margin-top: 0;
  }
  .intro p:last-child, .supporting p:last-child {
    margin-bottom: 0;
  }


  .requirements p:last-child {
    padding-bottom: 3em;
    padding-top: 3em;
    box-shadow: inset 0 0 100px rgba(255, 255, 255, 0.3);
  }


  /* sidebar styling  !!! */

  .design-selection, .zen-resources {
    position: absolute;
    top: 5px;
    right: 0;
    width: 14%;
    padding: 0 0 20px 0;
    margin: 0;
    overflow: visible;
    background: transparent;
  }
  .sidebar li {
    float: none;
    width: auto;
    padding-left: 0;
    padding-right: 0;
    margin: 0 29.4% 0 14.7%; /* 0 10% 0 5% of total width */
  }


  /* select a design */
  .design-selection li:first-child {
    border-top: 0;
  }
  .design-selection li:hover, .design-selection li:focus {
    background: none;
  }

  /* archives navigation */
  .design-archives {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 310px;

  /* sidebar styling !!!*/

    /*background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,0) 66%, rgba(0,0,0,0.2) 66%, rgba(33,84,95,0.3) 100%);  FF3.6+ */
    /*background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(66%,rgba(255,255,255,0)), color-stop(66%,rgba(33,84,95,0.3)), color-stop(100%,rgba(33,84,95,0))); /* Chrome,Safari4+ */
    /*background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 66%,rgba(33,84,95,0.3) 66%,rgba(33,84,95,0) 100%); /* Chrome10+,Safari5.1+ */
    /*background: -o-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 66%,rgba(33,84,95,0.3) 66%,rgba(33,84,95,0) 100%); /* Opera 11.10+ */
    /*background: -ms-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 66%,rgba(33,84,95,0.3) 66%,rgba(33,84,95,0) 100%); /* IE10+ */
    /*background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 66%,rgba(33,84,95,0.3) 66%,rgba(33,84,95,0) 100%); /* W3C */
  }
  .design-archives nav {
    display: block;
    position: absolute;
    right: 1%;
    top: 120px;
    width: 32%;
  }
  .design-archives li {
    margin: 0;
  }
  .design-archives .viewall a, .design-archives a::before {
    background: rgba(255,255,255,0.1);
    text-decoration: none;
  }

  .design-archives .viewall a:hover, .design-archives a:hover::before {
    background-color: rgba(255,255,255,0.2);
  }


  /* resources navigation */
  .zen-resources {
    position: absolute;
    top: 1310px;
    right: 0;
    background: transparent;
  }
  .zen-resources h3 {
    display: none;
  }
  .zen-resources ul {
    width: 55.9%;
    margin: 0 29.4% 0 14.7%;
  }
  .zen-resources li {
    padding: 1.5em 0 1.25em 0;
    margin: 0;
  }

}


@media only screen and (min-width: 1150px) {
  /* page footer */
  footer a {
    width: 76px;
    height: 76px;
    padding: 76px 0 0 0;
    margin: 0 3em;
  }
  footer a::before {
    font-size: 72px;
  }
}

/*
  Okay, look, I feel rather awful about this. But due to source order it
  seems like the only way to pull off the separated sidebar. Perhaps
  flexbox is the way forward here, once the syntax settles down a bit.
*/
@media only screen and (min-width: 1120px) {.zen-resources {top: 1490px;}}
@media only screen and (min-width: 1240px) {.zen-resources {top: 1460px;}}
@media only screen and (min-width: 1260px) {.zen-resources {top: 1420px;}}
@media only screen and (min-width: 1320px) {.zen-resources {top: 1390px;}}
@media only screen and (min-width: 1370px) {.zen-resources {top: 1360px;}}
@media only screen and (min-width: 1520px) {.zen-resources {top: 1330px;}}
@media only screen and (min-width: 1812px) {.zen-resources {top: 1300px;}}
@media only screen and (min-width: 1848px) {.zen-resources {top: 1270px;}}
@media only screen and (min-width: 2015px) {.zen-resources {top: 1240px;}}
@media only screen and (min-width: 2115px) {.zen-resources {top: 1210px;}}
@media only screen and (min-width: 2400px) {/* you're kidding, right? */}


@-webkit-keyframes koan {
    from {
      -webkit-transform: scale(1);
    opacity: 1;
    }
    to {
      -webkit-transform: scale(3);
    opacity: 0;
    }
}
@-moz-keyframes koan {
    from {
      -moz-transform: scale(1);
    opacity: 1;
    }
    to {
      -moz-transform: scale(3);
    opacity: 0;
    }
}
@keyframes koan {
    from {
      transform: scale(1);
    opacity: 1;
    }
    to {
      transform: scale(3);
    opacity: 0;
    }
}

/* high-DPI adjustments */
@media
    (min--moz-device-pixel-ratio: 1.5),
    (-o-min-device-pixel-ratio: 3 / 2),
    (-webkit-min-device-pixel-ratio: 1.5),
    (min-device-pixel-ratio: 1.5),
    (min-resolution: 1.5dppx) {

    header {
       /* background-image: url(contours@2x.png), url(noise.png), url(gridlines.png), url(huntington.jpg);*/
        background-size: 50% auto, auto, auto, cover;
    }

    .requirements p:last-child {
       /* background-image: url(contours@2x.png), url(gridlines.png), url(noise.png), url(koi.jpg);*/
        background-size: 50% auto, auto, auto, cover;
    }

    /*
        This shouldn't be necessary, but it is. Due to differences in how
        Chrome calculates gradients and % widths / padding, the archives
        spill out of the visual container at some screen sizes.
    */
    .design-archives nav {
        right: 3%;
        width: 29%;
    }
}

/* icon fonts */
@font-face {
	font-family: 'verdemoderna';
	/*src:url('verdemoderna.eot');*/
	/*src:url('verdemoderna.eot?#iefix') format('embedded-opentype'),
		url('verdemoderna.woff') format('woff'),
		url('verdemoderna.ttf') format('truetype'),
		url('verdemoderna.svg#verdemoderna') format('svg');*/
	font-weight: normal;
	font-style: normal;
}
/* common */

.rounded-search-box  {
/*  border-radius: 25px;
  border: 1px solid #0a0907;*/
  padding: 2px;
  width: 98%;
  height: 30%;
}

.span-criteria
{
    padding:1%;
}

.search-text {
 width: 380px;
}

.span-criteria-button
{
   position:relative;
   right: 1px;
   top:2px;
   margin-right:1px;
}

.div-searchbyName
{
    padding: 1%;
}


/* Page images, videos grid  */

.outer-grid {

 /*
   display: flex;

   flex-wrap: wrap;
   padding: 0 4px;
    */

    position: absolute;
       top: 50%;
       left: 50%;
       margin-top: -50px;
       margin-left: 100px;
       width: 90%;
       height: 90%;
}
.inner-grid {
   flex: 25%;
   max-width: 25%;
   width: 450px;
   height: 300px;
   padding: 4px;

}
.inner-grid img {
   margin-top: 8px;
   width: 100%;
   padding: 10px;
}
@media screen and (max-width: 800px) {
   .inner-grid {
      flex: 50%;
      max-width: 50%;
   }
}
@media screen and (max-width: 600px) {
   .inner-grid {
      flex: 100%;
      max-width: 100%;
   }
}
 /* End Page images, videos grid  */
 /* Grid for text  */
 .grid-container {
   display: table;
 }
 .grid-row {
   display: table-row;
 }
 .grid-column {
   display: table-cell;
    padding: 0 4px;
 }
 /* End - Grid for text  */


/* */
 .image-item-display {
   position: relative;
   padding: 0.75rem 1.25rem;
   background-color: #fff;
   border: 1px solid rgba(0, 0, 0, 0.125);
 }

div.gallery {
  margin: 5px;
  border: 1px solid #ccc;
  float: left;
  width: 380px;
}

div.gallery:hover {
  border: 1px solid #777;
}

div.gallery img {
  width: 100%;
  height: auto;
}

div.desc {
  padding: 10px;
  text-align: center;
}

/*    */


 /*
 Navbar drop down menu */

 .navbar a {
   float: left;
   font-size: 16px;
   color: white;
   text-align: center;
   padding: 14px 16px;
   text-decoration: none;
 }

 .dropdown {
   float: left;
   overflow: hidden;
 }

 .dropdown .dropbtn {
   font-size: 16px;
   border: none;
   outline: none;
   color: white;
   padding: 14px 16px;
   background-color: inherit;
   font-family: inherit;
   margin: 0;
 }

 .navbar a:hover, .dropdown:hover .dropbtn {
   background-color: red;
 }

 .dropdown-content {
   display: none;
   position: absolute;
   background-color: #f9f9f9;
   min-width: 160px;
   box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
   z-index: 4;
 }

 .dropdown-content a {
   float: none;
   color: black;
   padding: 12px 16px;
   text-decoration: none;
   display: block;
   text-align: left;
 }

 .dropdown-content a:hover {
   background-color: #ddd;
 }

 .dropdown:hover .dropdown-content {
   display: block;
 }



 /* blog style - move this to blog*/

  .blog-container {
      width: 1200px;
      height: 70%;
       /*
     overflow-y: scroll;
     max-height: 60%;
     */

  }

  .blog-center {

        overflow:scroll;  /* scroll when needed */
        width: 75%;
        overflow-y:scroll;

        position:relative;
        overflow:visible;
        overflow-x:scroll;

        margin-left: auto;
        margin-right: auto;
        float: left;

        top: 38%;
   }

  .blog-side-bar-right {
      overflow:hidden;  /* code added to prevent scroll */
      #margin-right:0px;
      width: 15%;


      float: right;
      border: 1px solid black;

      position: fixed;
      top: 23%;
      right: 11%;

  }

.blogImage {
     width : 100%;
     height: 600px;
}

.blogTitle {
    font-weight: bold;
    text-align: center;
    font-size: 27px;
    color : blue;
}


.categoryItem {
    padding: 1px;
    border: 1px solid black;
    background-color: white;
}


.featuredLink {
   color: white;
}

.featuredPostsDiv {
    display: inline-block;
    vertical-align:top;
    float:left;
    width: 220px;
    height: 80px;
    padding: 2px;
    margin: 2px;
    border: 1px solid black;
    color : white;
    background-color: black;
}


.gallaryContainerDiv {
     margin: 3%;
}

.formContainerDiv {
    width: 35%;
    height: 22%;
    margin: auto;
    border: 1px solid black;
    border-radius: 5px;
    background-color: white;
    padding: 20px;
}


############# ======================================

.contentlayer {
    text-align: center;
}
.innerlayer {
    animation: fadein 2s;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background-color: dodgerblue;
    min-width: 50%;
    max-width: 50%;
    border: 1px solid black;
    height: 80%;
}
.header {
    position: fixed;
    height:15%;
    width:100%;
}
.content {

  bottom:0;
  overflow-y:auto;
  height:80%;
  width:100%;
  position:fixed;
}


/**
 DropDown navbar menu
*/

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.fa-caret-down:before {
  content: "\f0d7";
}

* {
  box-sizing: border-box;
}

/* Create three equal columns that floats next to each other */
.menuColumn {
  float: left;
  width: 33.33%;
  padding: 16px;
  height: 250px; /* Should be removed. Only for demonstration */


    min-height: 60%;
    max-height: 60%;


}

 /* make-all-li-same-width-as-the-widest */
.menuColumn li {
    width: 100%
}


/* Clear floats after the columns */
.menuRow:after {
  content: "";
  display: table;
  clear: both;
}

.navbar a {

  font-size: 16px;
  color: white;
  text-align: center;
  /*
  padding: 14px 16px;
  float: left;
  */
  text-decoration: none;
}
/*
.subnav {
  float: left;
  overflow: hidden;
}

.subnav .subnavbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.navbar a:hover, .subnav:hover .subnavbtn {
  background-color: red;
}

.subnav-content {
  display: none;
  position: absolute;
 *//* left: 0;*//*
  background-color: black;
  width: 40%;
  z-index: 1;
}

.subnav-content a {
  float: left;
  color: white;
  text-decoration: none;
}

.subnav-content a:hover {
  background-color: #eee;
  color: black;
}

.subnav:hover .subnav-content {
  display: block;
}*/
/**
 End DropDown navbar menu
*/

/* New ---------- */
body {
  font-family: "Myriad Pro", Frutiger, "Lucida Grande", "Lucida Sans", "Lucida Sans Unicode", Verdana, sans-serif;
  font-size: 1.4em;
  margin-top: 4em;
  width: 100%;
  margin:0;
  padding:0;


 /* background-image: url("banner-img.png");
  background-position: 0 0;
  animation: animation-frames 1s steps(10, end) infinite;*/

}


ul.nav, ul.nav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
	float: left;
	border: 1px solid #486B02;
	background-color: #8BD400;
}

ul.nav li {
	float: left;
	width: 8em;
}

ul.nav li ul {
	position: absolute;
	width: 8em;
	left: -999em;
	margin-left: -1px;
}

.nav li:hover ul {
	left: auto;
}

ul.nav a {
	display: block;
	color: #2B3F00;
  text-decoration: none;
	padding: 0.3em 1em;
  border-right: 1px solid #486B02;
	border-left: 1px solid #E4FFD3;
}

ul.nav li li a {
	border-top: 1px solid #E4FFD3;
	border-bottom: 1px solid #486B02;
	border-left: 0;
	border-right: 0;
}

ul.nav li:last-child a {
	border-right: 0;
	border-bottom: 0;
}

/*
 Anchor background hover colour
*/

ul a:hover,
ul a:focus {
	/*color: #E4FFD3;
	background-color: #6DA203;*/

    background-color: #b3e0dc;
}

/* moving/ animated background */
/*#appBody {
    height: 100%;
    *//* max-height: 600px; *//*
    width: 1000px;
    background-color: hsla(200,40%,30%,.4);
    background-image:
      url('https://78.media.tumblr.com/cae86e76225a25b17332dfc9cf8b1121/tumblr_p7n8kqHMuD1uy4lhuo1_540.png'),
      url('https://78.media.tumblr.com/66445d34fe560351d474af69ef3f2fb0/tumblr_p7n908E1Jb1uy4lhuo1_1280.png'),
      url('https://78.media.tumblr.com/8cd0a12b7d9d5ba2c7d26f42c25de99f/tumblr_p7n8kqHMuD1uy4lhuo2_1280.png'),
      url('https://78.media.tumblr.com/5ecb41b654f4e8878f59445b948ede50/tumblr_p7n8on19cV1uy4lhuo1_1280.png'),
      url('https://78.media.tumblr.com/28bd9a2522fbf8981d680317ccbf4282/tumblr_p7n8kqHMuD1uy4lhuo3_1280.png');
    background-repeat: repeat-x;
    background-position:
      0 20%,
      0 100%,
      0 50%,
      0 100%,
      0 0;
    background-size:
      2500px,
      800px,
      500px 200px,
      1000px,
      400px 260px;
    animation: 50s para infinite linear;
    }*/

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}

/*
#appBody {
 could serve as animated home page background
 width: 100%;
 height: 800px;
 background: url('http://static1.360vrs.com/pano-content/judith-stone-at-sunset-east-farndon/640px-360-panorama.jpg');
 -webkit-animation: backgroundScroll 20s linear infinite;
 animation: backgroundScroll 20s linear infinite;
 }
*/

 @-webkit-keyframes backgroundScroll {
  from {background-position: 0 0;}
  to {background-position: -400px 0;}
 }

 @keyframes backgroundScroll {
  from {background-position: 0 0;}
  to {background-position: -400px 0;}
 }

/* End New */

/**
 Pop up trial
*/
/*
.window {
	position: absolute;
	top: 80px;
	z-index: 100;
	background-color: black;
	width: 800px;
	height: 400px;
	border-radius: 8px 8px 0 0;
	box-shadow: 8px 8px 6px -6px black;
	opacity: 0.9;
	display: block;
}

.mainWindow {
	padding: 20px;
}

.large {
	width: 1000px;
	height: 500px;
}
*/
