/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;



  /*  background-color: rgb(0,0,0);  Fallback color
    background-color: rgba(0,0,0,0.4);  Black w/ opacity */


}

/* Modal Content */
.modal-content {
/*  background-color: #fefefe;*/
   border-radius: 5px;
   border: 2px solid gray;

    color: green;

}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
/* The Close Button - 2nd option */
.tag-remove::before {
  content: 'X';
  color: #fff;
  font-weight: 300;
  font-family: Arial, sans-serif;


}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
/*  padding: 2px 16px;*/
  background-color: #5cb85c;
  color: white;
}

.modal-body {
 /* padding: 2px 16px;*/
   background-color: white;
   color: green;

    font-size: 25px;
    font-weight: bold;
   /* background-image: linear-gradient(to right, #66a80f, #c0eb75);*/
}

.modal-footer {
  padding: 2px 16px;
  background-color: white;
  color: white;
}


.yes {
        background-color: #5c940d;
        display: inline-block;
        border-radius: 5px;
        border: 2px solid gray;
        padding: 5px;
        margin-right: 10px;
        text-align: center;
        width: 60px;
        float: right;
    }

  .no {
        background-color: #22b8cf;
        display: inline-block;
        border-radius: 5px;
        border: 2px solid gray;
        padding: 5px;
        margin-right: 10px;
        text-align: center;
        width: 95px;
        float: right;
}


/* Add Animation */
/*@-webkit-keyframes slideIn {
  from {bottom: -300px; opacity: 0}
  to {bottom: 0; opacity: 1}
}

@keyframes slideIn {
  from {bottom: -300px; opacity: 0}
  to {bottom: 0; opacity: 1}
}

@-webkit-keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1}
}

@keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1}
}*/

